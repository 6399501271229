.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
} 

.contact__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

.contact__option h5 {
    color: var(--color-light);
}

.contact__option h4 {
    font-weight: 500;
}

form span {
    font-size: 0.8rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 1024px){
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
  
@media screen and (max-width: 600px){
    .container.contact__container {
        width: var(--container-width-sm);
    }
}




.liquid-btn {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: #4973ff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    transition: background 0.3s ease-in-out;
  }
  
  .liquid-btn span {
    position: relative;
    z-index: 1;
  }
  
  .liquid-btn div {
    position: absolute;
    top: -80px;
    left: 0;
    width: 200px;
    height: 200px;
    background: #4973ff;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
    border-radius: 50%;
    z-index: 0;
    transform: translateX(-50%);
    pointer-events: none;
  }
  
  .liquid-btn:hover div {
    top: -120px;
  }
  
  .liquid-btn div::before,
  .liquid-btn div::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    height: 200%;
    transform: translate(-50%, -75%);
  }
  
  .liquid-btn div::before {
    border-radius: 45%;
    background: rgba(20, 20, 20, 1);
    animation: liquidAnimation 10s linear infinite;
  }
  
  .liquid-btn div::after {
    border-radius: 40%;
    background: rgba(20, 20, 20, 0.5);
    animation: liquidAnimation 10s linear infinite;
  }
  
  @keyframes liquidAnimation {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }
  